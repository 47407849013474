<template>
  <div class="sidebar bg-light" :class="{ 'sidebar--open': isOpen }">
    <h2 class="h5 p-3" style="color: rgb(28, 132, 84);">HopStats</h2>
    <ul class="list-unstyled">
      <!-- vijver kaart -->
      <li class="mb-2">
        <router-link to="/kaart" class="d-flex align-items-center text-dark text-decoration-none p-3">
          <i class="bi bi-map me-2"></i>
          Kaart
        </router-link>
      </li>
      <!-- vijver overzicht -->
      <li class="mb-2">
        <router-link to="/vijvers" class="d-flex align-items-center text-dark text-decoration-none p-3">
          <i class="bi bi-water me-2"></i>
          Vijvers
        </router-link>
      </li>
      <!-- sessie overzicht -->
      <li class="mb-2">
        <router-link to="/sessies" class="d-flex align-items-center text-dark text-decoration-none p-3">
          <i class="fa-solid fa-list-ul me-2"></i>
          Sessies
        </router-link>
      </li>
      <!-- parameters -->
      <li class="mb-2">
        <router-link to="/parameters" class="d-flex align-items-center text-dark text-decoration-none p-3">
          <i class="fa-solid fa-gears me-2"></i>
          Parameters
        </router-link>
      </li>
      <!-- user validation -->
      <li class="mb-2">
        <router-link to="/gebruikersvalidatie" class="d-flex align-items-center text-dark text-decoration-none p-3">
          <i class="fa-solid fa-user-check me-2"></i>
          Gebruikersvalidatie
          <template v-if="$store.state.uservalidations['to-review'] > 0">
            <span class="badge rounded-pill bg-warning text-dark ms-2">{{ $store.state.uservalidations['to-review'] }} te valideren</span>
          </template>
        </router-link>
      </li>
      <!-- login -->
      <li class="mb-2" @click="$root.logOut()">
        <span class="d-flex align-items-center text-dark text-decoration-none p-3">
          <i class="fa-solid fa-right-from-bracket me-2"></i>
          Afmelden
        </span>
      </li>
      <!-- frog icon -->
      <li class="sidebar__bottom">
        <i class="fa-solid fa-frog" style="color: rgb(28,132,84)"></i>
      </li>
    </ul>
  </div>
</template>

<script>

  export default {
    name: 'fuikSidebar',
    data() {
      return {
        // toggle sidebar - off
        isOpen: false,
      }
    },
    methods: {
      // function - open sidebar
      toggle() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -300px;
  z-index: 9999;
  width: 300px;
  padding-top: 50px;
  overflow-y: auto;
  transition: left 0.3s ease-in-out;
}

.sidebar--open {
  left: 0;
}

.sidebar__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>