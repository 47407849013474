import { createRouter, createWebHistory } from "vue-router";
import layout from '@/layout/index.vue';

const routes = [
  // DEFAULT ROUTE
  {
    path: "/",
    name: "default",
    component: function () {
      return import('../components/Map.vue')
    }
  },
  // PARAMETERS ROUTE
  {
    path: '/parameters',
    name: 'ParametersOverlayHome',
    component: function () {
      return import('../components/Parameters.vue')
    },
    meta: {
      breadcrumbName: 'parameters'
    }
  },
  // GEBRUIKERSVALIDATIE ROUTE
  {
    path: '/gebruikersvalidatie',
    name: 'UsersValidationOverlay',
    component: function () {
      return import('../components/UsersValidation.vue')
    },
    meta: {
      breadcrumbName: 'gebruikersvalidatie'
    }
  },
  // LOGIN ROUTE
  {
    path: '/aanmelden',
    name: 'LoginOverlay',
    component: function () {
      return import('../components/Login.vue')
    },
    meta: {
      breadcrumbName: 'aanmelden'
    }
  },
  // MAP ROUTE
  {
    path: '/kaart',
    name: 'Map',
    component: layout,
    meta: {
      breadcrumbName: 'kaart'
    },
    children: [
      {
        path: '',
        name: 'MapHome',
        component: () => import('../components/Map.vue'),
      },
      {
        path: '/vijver/:id',
        name: 'PondDetail', // Update the route name to 'MapPondDetail'
        component: () => import('../components/PondDetail.vue'),
        meta: {
          breadcrumbName: 'vijver'
        }
      }
    ]
  },
  // POND OVERLAY ROUTE
  {
    path: '/vijvers',
    name: 'PondOverlay',
    component: layout,
    meta: {
      breadcrumbName: 'vijvers'
    },
    children: [
      {
        path: '',
        name: 'PondOverlayHome',
        component: () => import('../components/PondOverlay.vue'),
      },
      {
        path: '/vijver/:id',
        name: 'PondDetail', // Update the route name to 'PondOverlayDetail'
        component: () => import('../components/PondDetail.vue'),
        meta: {
          breadcrumbName: `vijver`,
        }
      },
      {
        path: '/vijver/:id',
        name: 'PondDetail', // Update the route name to 'PondOverlayDetail'
        component: () => import('../components/PondDetail.vue'),
        meta: {
          breadcrumbName: `vijver`,
        }
      }
    ]
  },
  // FOOTER ROUTE
  {
    path: '/footer',
    name: 'Footer',
    component: function () {
      return import('../components/Footer.vue')
    }
  },
  // HEADER ROUTE
  {
    path: '/header',
    name: 'Header',
    component: function () {
      return import('../components/Header.vue')
    }
  },
  // SESSION OVERLAY ROUTE
  {
    path: '/sessies',
    name: 'SessionOverlay',
    component: layout,
    meta: {
      breadcrumbName: 'sessies'
    },
    // SESSION DETAIL ROUTE
    children: [
      {
        path: '',
        name: 'SessionOverlayHome',
        component: () => import('../components/SessionOverlay.vue'),
      },
      {
        path: '/sessie/:id',
        name: 'SessionDetail',
        component: () => import('../components/SessionDetail.vue'),
        meta: {
          breadcrumbName: 'sessie'
        }
      }
    ]
  },
  // ADD POND ROUTE
  {
    path: '/vijver-toevoegen',
    name: 'AddPond',
    component: function () {
      return import('../components/PondAdd.vue')
    }
  },
  // ADD POND IN REGION
  {
    path: '/vijver-toevoegen/:region',
    props: true,
    name: 'AddPondInRegion',
    component: () => import('../components/PondAdd.vue')
  },
  // ADD POND IN REGION
  {
    path: '/vijver/:pondid/bewerken',
    props: true,
    name: 'EditPond',
    component: () => import('../components/PondEdit.vue')
  },
  // CHART ROUTE
  {
    path: '/chart',
    name: 'Chart',
    component: function () {
      return import('../components/Chart.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router