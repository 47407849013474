<template>
  <footer class="bg-light py-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col d-flex justify-content-center flex-column align-items-center box" @click="$router.push('/kaart')">
          <router-link to="/kaart" class="btn btn-success btn-block bg-transparent" style="border:none;">
            <i class="bi bi-map" style="color: black; font-size: 1.5rem;"></i>
          </router-link>
          <h3>Kaart</h3>
        </div>
          <div class="col d-flex justify-content-center flex-column align-items-center box" @click="$router.push('/vijvers')">
          <router-link to="/vijvers" class="btn btn-success btn-block bg-transparent" style="border:none;">
            <i class="bi bi-water" style="color: black; font-size: 1.5rem;"></i>
          </router-link>
          <h3>Vijvers</h3>
        </div>
          <div class="col d-flex justify-content-center flex-column align-items-center box" @click="$router.push('/sessies')">
          <router-link to="/sessies" class="btn btn-success btn-block bg-transparent" style="border:none;">
            <i class="fa-solid fa-list-ul me-2" style="color:#212529"></i>
          </router-link>
          <h3>Sessies</h3>
        </div>
      </div>
    </div>
  </footer>
</template>

<!-- footer script -->
<script>
  export default{
    name: "fuikFooter",
    data(){
      return{
        fuikenHovered: false,
        vijverkaartHovered: false,
        afschotHovered: false
      }
    },
    methods: {

    }
  }
</script>

<!-- footer script -->
<style scoped>
  .box:hover {
    background-color: green;
    color: white;
    cursor: pointer;
  }
</style> 