<template>
  <div>
    <Header v-if="logged_in" />
    <Breadcrumbs v-if="logged_in" />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <Footer v-if="logged_in" />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Breadcrumbs from './components/Breadcrumbs.vue';
import { DateTime } from "luxon";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  data() {
    return {
      logged_in: false,
    }
  },
  components: {
    Header,
    Footer,
    Breadcrumbs,
  },
  mounted() {
    this.checkLoggedIn();
  },
  computed: {
    ...mapGetters(['getUsers']),
  },
  methods: {
    checkLoggedIn() {
      if (localStorage.getItem('hopstats-api-key-expiry') !== null && localStorage.getItem('hopstats-api-key') !== null && localStorage.getItem('hopstats-api-key-name') !== null)
      {
        // Localstorage keys exists
        let expiry = localStorage.getItem('hopstats-api-key-expiry');
        let now = DateTime.now().toISO();
        if (now > expiry) {
          // Login time expired
          this.logOut();
        } else {
          // Still logged in
          let api_headers = {};
          api_headers[localStorage.getItem('hopstats-api-key-name')] = localStorage.getItem('hopstats-api-key');
          this.$store.state.api_headers = api_headers;
          this.logged_in = true;
        }
      } else {
        this.logged_in = false;
        this.$router.push('/aanmelden');
      }
    },
    logOut()
    {
        localStorage.removeItem('hopstats-api-key-name');
        localStorage.removeItem('hopstats-api-key');
        localStorage.removeItem('hopstats-api-key-expiry');
        window.location.reload();
    },
    checkUserValidationStatus()
    {
      this.$store.state.uservalidations['to-review'] = 0;
      this.$store.state.uservalidations['accepted'] = 0;
      this.$store.state.uservalidations['rejected'] = 0;

      this.$store.dispatch('getUsers').then(() => {
        // Iterate user validation statusses
        this.getUsers.forEach(user => {
          // let not_validated = 0;
          this.$store.state.uservalidations[user.validation_status]++;
        });
      })
    }
  },
}
</script>