//BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css"
//BOOTSTRAP ICONS
import 'bootstrap-icons/font/bootstrap-icons.css';
//LEAFLET
import 'leaflet/dist/leaflet.css';
//FONTAWESOME FROG ICON
import '@fortawesome/fontawesome-free/css/all.css';
//ROUTER
import router from './router/router';
//STORE
import store from './store/store';

import { createApp } from 'vue';
import App from './App.vue';

createApp(App).use(router).use(store).mount('#app')

import "bootstrap/dist/js/bootstrap.js"
