<template>
  <div class="header">
    <nav class="nav justify-content-between align-items-center">
      <!-- header left side -->
      <div class="d-flex">
        <button type="button" class="btn btn-link" @click="toggleSidebar()">
          <Sidebar ref="sidebar" />
          <i class="bi bi-filter-left" style="color: black; font-size: 2.5rem;"></i>
        </button>
        <h1 class="ml-4 site-name">{{ siteName }}</h1>
      </div>
      </nav>
  </div>
  <template v-if="$store.state.uservalidations['to-review'] > 0 && $route.name != 'UsersValidationOverlay'">
    <div class="alert alert-warning alert-dismissible fade show m-2" role="alert">
      <strong><i class="fa-solid fa-triangle-exclamation"></i> {{ $store.state.uservalidations['to-review'] }} gebruiker<template v-if="$store.state.uservalidations['to-review'] > 1">s</template> te valideren</strong><br>Enkel sessies van gevalideerde gebruikers worden getoond
      <router-link to="/gebruikersvalidatie" class="">
        <br><a>Klik hier om te valideren</a>
      </router-link>
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </template>
</template>


<!-- header script -->
<script>
  // sidebar component
  import Sidebar from './Sidebar.vue'

  export default {
    name: 'pageHeader',
    components: {
      Sidebar
    },
    data() {
      return {
        showNav: false,
      }
    },
    methods: {
      toggleSidebar() {
        this.isOpen = !this.isOpen;
        this.$refs.sidebar.toggle();
      },
    },
    mounted() {
      this.$root.checkUserValidationStatus();

    },
    computed: {
      siteName(){
        switch (this.$route.name){
          case 'MapHome':
            return 'Kaart';
          case 'PondOverlayHome':
            return 'Vijvers';
          case 'PondDetail':
            return 'Vijver';
          case 'SessionOverlayHome':
            return 'Sessies';
          case 'SessionDetail':
            return 'Sessie';
          case 'ParametersOverlayHome':
            return 'Parameters';
          case 'UsersValidationOverlay':
            return 'Gebruikersvalidatie';
          case 'AddPond':
            return 'Vijver toevoegen in nieuwe regio';
          case 'AddPondInRegion':
            return 'Vijver toevoegen in ' + this.$route.params.region;
          case 'EditPond':
            return 'Vijver bewerken';
          default:
            return 'Beheertool';
        }
      }
    }
  }
</script>

<!-- header style -->
<style scoped>

.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}
</style>
<script setup>
</script>