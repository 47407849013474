<template>
  <nav>
    <ul class="breadcrumbs">
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.name">
        <router-link :to="breadcrumb.route" :class="{ active: index === breadcrumbs.length - 1 }">
          {{ breadcrumb.name }}
        </router-link>
        <span v-if="index !== breadcrumbs.length - 1" class="separator">&gt;</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'FuikBreadcrumbs',
  computed: {
    breadcrumbs() {
      const matchedRoutes = this.$route.matched;
      const breadcrumbs = [];
      for (let i = 0; i < matchedRoutes.length; i++) {
        const route = matchedRoutes[i];
        if (route.meta && route.meta.breadcrumbName) {
          const breadcrumb = {
            name: this.getBreadcrumbName(route),
            route: this.getRoutePath(route)
          };
          breadcrumbs.push(breadcrumb);
        }
      }
      return breadcrumbs;
    }
  },
  methods: {
    getBreadcrumbName(route) {
      const breadcrumbName = route.meta.breadcrumbName;
      if (breadcrumbName.includes(':')) {
        const paramName = breadcrumbName.split(':')[1];
        return route.params[paramName];
      }
      return breadcrumbName;
    },
    getRoutePath(route) {
      if (route.meta && route.meta.breadcrumbName) {
        return route.path;
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
.breadcrumbs {
  list-style: none;
  padding-left: 0.5;
  margin: 0;
  display: flex;
  align-items: center;
}

.separator {
  margin: 0 0.5rem;
  color: #ccc;
}

</style>