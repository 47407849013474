// MAIN STORE FILE

import { createStore } from 'vuex';
import axios from "axios";

const store = createStore({
  state: {
    api_url: '/fuik-backend/public/index.php/api/',
    // api_url: '/afvangst-back/public/index.php/api/', // Todo: change line to above line
    api_headers: {},
    lifestages: ['l00', 'l0', 'l1', 'l2', 'm1', 'm2', 'am', 'av'],
    actiontypesLookup: {},
    sessions: [],
    ponds: [],
    pondsLookup: {},
    pond: [],
    regions: [],
    session: {},
    bycatchName: [],
    parameters: [],
    users: [],
    uservalidations: {'accepted':0, 'rejected':0, 'to-review':0},
    sescatch: {},
    bycatchtypes: [],
  },
  mutations: {
    // SET  API
    SET_SESSIONS(state, sessions) {
      state.sessions = sessions;
    },
    SET_PONDS(state, ponds) {
      state.ponds = ponds;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    SET_PONDSLOOKUP(state, pondslookup) {
      state.pondsLookup = pondslookup;
    },
    SET_POND(state, pond) {
      state.pond = pond;
    },
    SET_SESSION(state, session) {
      state.session = session;
    },
    SET_BYCATCHNAME(state, bycatchName) {
      state.bycatchName = bycatchName;
    },
    SET_PARAMETERS(state, parameters) {
      state.parameters = parameters;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_CATCH(state, sescatch) {
      state.sescatch = sescatch;
    },
    SET_BYCATCHTYPES(state, bycatchtypes) {
      state.bycatchtypes = bycatchtypes;
    },
    SET_ACTIONTYPESLOOKUP(state, actiontypesLookup) {
      state.actiontypesLookup = actiontypesLookup;
    },
  },
  actions: {
    // FETCH API
    async getSessions({ commit }) {
      const sessionsResponse = await axios.get(this.state.api_url + 'sessions', {
        headers: this.state.api_headers
      });
      const sessions = sessionsResponse.data;
      commit('SET_SESSIONS', sessions);
    },
    async getPonds({ commit }) {
      const pondsResponse = await axios.get(this.state.api_url + 'ponds/includes/latest-catches', {
        headers: this.state.api_headers
      });
      const ponds = pondsResponse.data;
      commit('SET_PONDS', ponds);

      const pondsLookup = {};
      const regions = [];
      ponds.forEach(pond => {

        if (pond.pond_part === null) {
          pond.pond_part = '';
        }

        pondsLookup['pond_' + pond.id] = pond;

        if (regions.indexOf(pond.region) === -1) {
          regions.push(pond.region);
        }
      })
      commit('SET_PONDSLOOKUP', pondsLookup);
      // Sort regions
      regions.sort();
      commit('SET_REGIONS', regions);
    },
    async getPond({ commit }, pondId) {
      const pondResponse = await axios.get(this.state.api_url + `ponds/${pondId}/includes/latest-catches,sessions`, {
        headers: this.state.api_headers
      });
      const pond = pondResponse.data;
      commit('SET_POND', pond);
    },
    async getSession({ commit }, sessionId) {
      const sessionResponse = axios.get(this.state.api_url + `sessions/${sessionId}`, {
        headers: this.state.api_headers
      })
      const session = sessionResponse.data;
      commit('SET_SESSION', session);
    },
    async getBycatchName({ commit}) {
      const bycatchNameResponse = await axios.get(this.state.api_url + '/sessions/includes/catches,bycatches', {
        headers: this.state.api_headers
      })
      const bycatchName = bycatchNameResponse.data;
      commit('SET_BYCATCHNAME', bycatchName);
    },
    async getParameters({ commit}) {
      const parametersResponse = await axios.get(this.state.api_url + '/parameters-per-type', {
        headers: this.state.api_headers
      })
      const parameters = parametersResponse.data;
      commit('SET_PARAMETERS', parameters);

      const actionTypesLookup = {};
      parameters.action_type.forEach(action_type => {
        actionTypesLookup['action_type_' + action_type.value] = action_type.label;
      })
      commit('SET_ACTIONTYPESLOOKUP', actionTypesLookup);
    },
    async getUsers({ commit}) {
      const usersResponse = await axios.get(this.state.api_url + '/validation-emails', {
        headers: this.state.api_headers
      })
      const users = usersResponse.data;
      commit('SET_USERS', users);
    },
    async getCatch({commit}, id) {
      const getCatchResponse = await  axios.get(this.state.api_url + `/catches/${id}`, {
        headers: this.state.api_headers
      })
      const sescatch = getCatchResponse.data;
      commit('SET_CATCH', sescatch);
    },
    async getBycatchTypes({commit}) {
      const bycatchTypesResponse = await axios.get(this.state.api_url + '/bycatchtypes', {
        headers: this.state.api_headers
      })
      const bycatchtypes = bycatchTypesResponse.data;
      commit('SET_BYCATCHTYPES', bycatchtypes);
    }
  },
  getters: {
    // GET API
    getSessions(state) {
      return state.sessions;
    },
    getPonds(state) {
      return state.ponds;
    },
    getRegions(state) {
      return state.regions;
    },
    getPondsLookup(state) {
      return state.pondsLookup;
    },
    getPond(state) {
      return state.pond;
    },
    getSession(state) {
      return state.session;
    },
    getBycatchName(state) {
      return state.bycatchName;
    },
    getParameters(state) {
      return state.parameters;
    },
    getUsers(state) {
      return state.users;
    },
    getCatch(state) {
      return state.sescatch;
    },
    getBycatchTypes(state) {
      return state.bycatchtypes;
    }
  }
});

export default store;